import React, { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'
import useContextStore from '~/stores/useContextStore'

import Homepage from '~/pages/Homepage'

import Splash from '~/components/Splash/index'
import Dashboard from '~/pages/Dashboard'
import Prices from '~/pages/Prices'
import Schedule from '~/pages/Schedule'
import Students from '~/pages/Students'
import Teachers from '~/pages/Teachers'
import { PrivateRoute, ValidateRoute } from './utils'

import Admin from '~/pages/Admin'
import Permissions from '~/pages/Admin/Permissions'
import Profiles from '~/pages/Admin/Profiles'
import Rooms from '~/pages/Admin/Rooms'
import Schools from '~/pages/Admin/Schools'
import ShowSchool from '~/pages/Admin/Schools/show'
import Subjects from '~/pages/Admin/Subjects'
import Users from '~/pages/Admin/Users'
import DashAdmin from '~/pages/Dashboard/Admin'
import DashDashOffice from '~/pages/Dashboard/Office'
import DashParents from '~/pages/Dashboard/Parents'
import DashPaymentStudents from '~/pages/Dashboard/Students'
import DashTeachers from '~/pages/Dashboard/Teachers'
import EventCreate from '~/pages/EventManager/Create'
import EventEditor from '~/pages/EventManager/Editor'
import Profile from '~/pages/Profile'
import StatusError from '~/pages/StatusError'
import ShowStudentPage from '~/pages/Students/show'
import ShowTeacherPage from '~/pages/Teachers/show'

export const AppRouter = () => {
  const { initialized, keycloak } = useKeycloak()
  const { setCurrentProfile, setUserKeycloak } = useContextStore()

  useEffect(() => {
    if (!initialized || !keycloak?.authenticated) {
      return
    }

    const profiles = keycloak?.idTokenParsed?.profiles
    const hasAdmin = profiles?.includes('admin')
    setCurrentProfile(hasAdmin ? 'admin' : profiles[0])
    setUserKeycloak(keycloak?.idTokenParsed)
  }, [initialized])

  if (!initialized) {
    return <Splash />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to='/portal/dashboard' replace />} />

        <Route path='/portal' element={<PrivateRoute />}>
          <Route
            path='dashboard'
            element={
              <ValidateRoute have={['read:dashboard']}>
                <Dashboard />
              </ValidateRoute>
            }
          />
          <Route
            path='dashboard/office'
            element={
              <ValidateRoute have={['read:dashboard']} is={['secretaria']}>
                <DashDashOffice />
              </ValidateRoute>
            }
          />
          <Route
            path='dashboard/admin'
            element={
              <ValidateRoute have={['read:dashboard']} is={['admin']}>
                <DashAdmin />
              </ValidateRoute>
            }
          />
          <Route
            path='dashboard/teacher'
            element={
              <ValidateRoute have={['read:dashboard']} is={['professores']}>
                <DashTeachers />
              </ValidateRoute>
            }
          />
          <Route
            path='dashboard/payment-student'
            element={
              <ValidateRoute
                have={['read:dashboard']}
                is={['admin', 'secretaria']}>
                <DashPaymentStudents />
              </ValidateRoute>
            }
          />
          <Route
            path='dashboard/parents'
            element={
              <ValidateRoute
                have={['read:dashboard']}
                is={['admin', 'secretaria']}>
                <DashParents />
              </ValidateRoute>
            }
          />

          <Route
            path='prices'
            element={
              <ValidateRoute have={['read:prices']}>
                <Prices />
              </ValidateRoute>
            }
          />
          <Route
            path='schedule'
            element={
              <ValidateRoute have={['read:schedules']}>
                <Schedule />
              </ValidateRoute>
            }
          />
          <Route
            path='profile'
            element={
              <ValidateRoute have={['read:profile']}>
                <Profile />
              </ValidateRoute>
            }
          />

          <Route path='*' element={<StatusError code={404} />} />
        </Route>

        <Route path={'/portal/teachers'} element={<PrivateRoute />}>
          <Route
            path=''
            element={
              <ValidateRoute have={['read:teachers']}>
                <Teachers />
              </ValidateRoute>
            }
          />
          <Route
            path=':id'
            element={
              <ValidateRoute have={['read:teachers']}>
                <ShowTeacherPage />
              </ValidateRoute>
            }
          />
          <Route path='*' element={<StatusError code={404} />} />
        </Route>

        <Route path={'/portal/students'} element={<PrivateRoute />}>
          <Route
            path=''
            element={
              <ValidateRoute have={['read:students']}>
                <Students />
              </ValidateRoute>
            }
          />
          <Route
            path=':id'
            element={
              <ValidateRoute have={['read:students']}>
                <ShowStudentPage />
              </ValidateRoute>
            }
          />
          <Route path='*' element={<StatusError code={404} />} />
        </Route>

        <Route path={'/portal/admin'} element={<PrivateRoute />}>
          <Route
            path={''}
            element={
              <ValidateRoute have={['read:admin']}>
                <Admin />
              </ValidateRoute>
            }
          />
          <Route
            path={'users'}
            element={
              <ValidateRoute have={['read:users']}>
                <Users />
              </ValidateRoute>
            }
          />
          <Route
            path={'rooms'}
            element={
              <ValidateRoute have={['read:rooms']}>
                <Rooms />
              </ValidateRoute>
            }
          />
          <Route
            path={'subjects'}
            element={
              <ValidateRoute have={['read:subjects']}>
                <Subjects />
              </ValidateRoute>
            }
          />
          <Route
            path={'schools'}
            element={
              <ValidateRoute have={['read:schools']}>
                <Schools />
              </ValidateRoute>
            }
          />
          <Route
            path={'schools/:id'}
            element={
              <ValidateRoute have={['read:schools']}>
                <ShowSchool />
              </ValidateRoute>
            }
          />
          <Route
            path={'profiles'}
            element={
              <ValidateRoute have={['read:profiles']}>
                <Profiles />
              </ValidateRoute>
            }
          />
          <Route
            path={'profiles/:id'}
            element={
              <ValidateRoute have={['read:permissions']}>
                <Permissions />
              </ValidateRoute>
            }
          />
        </Route>

        <Route path={'/portal/events'} element={<PrivateRoute />}>
          <Route
            path=':id'
            element={
              <ValidateRoute have={['read:events']}>
                <EventEditor />
              </ValidateRoute>
            }
          />
          <Route
            path={'create'}
            element={
              <ValidateRoute have={['create:events']}>
                <EventCreate />
              </ValidateRoute>
            }
          />
        </Route>

        <Route path='*' element={<StatusError code={404} />} />

        <Route path='/login' element={<Homepage />} />
      </Routes>
    </BrowserRouter>
  )
}
