import { LockOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import {
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Statistic,
  Tag,
  Typography,
} from 'antd'
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { InlineAvatar } from '~/components/InlineAvatar'
import Development from '~/components/Students/Development'
import Logs from '~/components/Students/Logs'
import Payments from '~/components/Students/Payments'
import Profile from '~/components/Students/Profile'
import { MaskMoney } from '~/helpers/Masks'
import api from '~/services/axios'
import useContextStore from '~/stores/useContextStore'

const ShowStudentPage = () => {
  const { currentProfile } = useContextStore()

  const { id } = useParams()
  const query = new URLSearchParams(useLocation().search)

  const [currentView, setCurrentView] = useState(query.get('view') || 'profile')

  const { data: student, isLoading } = useQuery(['student', id], () =>
    api.get(`/api/students/${id}`).then((res) => res.data),
  )

  const tabs = [
    {
      key: 'profile',
      tab: 'Perfil',
      children: <Profile id={id || ''} />,
    },
    {
      key: 'performance',
      tab: 'Desempenho',
      children: <Development key={'performance'} id={id || ''} />,
    },
    {
      key: 'statement',
      tab: 'Extrato',
      children: <Payments key={'statement'} id={id || ''} />,
    },
    {
      key: 'logs',
      tab: <span>{currentProfile !== 'admin' && <LockOutlined />} Logs</span>,
      children: <Logs key={'logs'} id={id || ''} />,
      disabled: currentProfile !== 'admin',
    },
  ]

  const handleTabChange = (key) => {
    if (key === 'logs' && currentProfile !== 'admin') {
      key = 'profile'
    }

    setCurrentView(key)
    query.set('view', key)
    window.history.replaceState({}, '', `${window.location.pathname}?${query}`)
  }

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      <Card bordered={false}>
        <Descriptions
          title={
            <>
              <InlineAvatar name={student?.name} src={student?.picture} />{' '}
              {student?.name}
            </>
          }>
          <Descriptions.Item label='Username'>
            {student?.username}
          </Descriptions.Item>
          <Descriptions.Item label='Telefone'>
            {student?.phone}
          </Descriptions.Item>
          <Descriptions.Item label='Endereço'>
            {student?.adress}
          </Descriptions.Item>

          <Descriptions.Item label='Ano'>
            {student?.grades
              .filter((grade) => grade.year === new Date().getFullYear())
              .map((grade) => <Tag key={grade.type}>{grade.type}</Tag>)}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      {/* cards statistics */}
      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} md={5} xl={5}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title={`${student?.statistics?.open_payments_count} Pagamentos em Aberto`}
              value={MaskMoney(student?.statistics?.open_payments_total)}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>

        <Col xs={12} sm={12} md={5} xl={5}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title={`${student?.statistics?.future_events_count} Aulas Futuras`}
              value={MaskMoney(student?.statistics?.future_events_total)}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>

        <Col xs={12} sm={12} md={5} xl={5}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title={`${student?.statistics?.cancelled_events_count} Aulas Canceladas`}
              value={MaskMoney(student?.statistics?.cancelled_events_total)}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>

        <Col xs={12} sm={12} md={5} xl={5}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title={`${student?.statistics?.payments_count} Aulas Pagas`}
              value={MaskMoney(student?.statistics?.payments_total)}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>

        <Col xs={24} sm={24} md={4} xl={4}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title='Total de Aulas'
              value={student?.statistics?.events_count}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Card
        tabList={tabs}
        activeTabKey={currentView}
        onTabChange={(key) => {
          handleTabChange(key)
        }}
        styles={{
          body: {
            padding: 8,
          },
        }}
        bordered={false}>
        {/* <Row gutter={[8, 8]}>
          {tabs.find((tab) => tab.key === currentView)?.children}
        </Row> */}
      </Card>
    </Space>
  )
}

export default ShowStudentPage
