import {
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  notification,
  Row,
  TimePicker,
} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import api from '~/services/axios'
import { queryClient } from '~/services/queryClient'

const ChangeTime = ({ event }) => {
  const [form] = Form.useForm()
  const [modalChangeTime, setModalChangeTime] = useState(false)

  const [date, setDate] = useState(dayjs(event?.date, 'YYYY-MM-DD'))
  const [init_hour, setInitHour] = useState(dayjs(event?.int_time, 'HH:mm'))
  const [end_hour, setEndHour] = useState(dayjs(event?.end_time, 'HH:mm'))
  const [duration, setDuration] = useState(
    end_hour.diff(init_hour, 'hour', true),
  )
  const [type, setType] = useState(event?.service?.name)

  useEffect(() => {
    setDate(dayjs(event?.date, 'YYYY-MM-DD'))
    setInitHour(dayjs(event?.int_time, 'HH:mm'))
    setEndHour(dayjs(event?.end_time, 'HH:mm'))
    setDuration(end_hour.diff(init_hour, 'hour', true))
    setType(event?.service?.name)
  }, [event, event?.service?.name])

  const watchDuration = Form.useWatch('duration', form)
  const watchInitTime = Form.useWatch('init_hour', form)

  // on duration change
  useEffect(() => {
    if (watchDuration === undefined) {
      return
    }

    form.setFieldsValue({
      end_hour: calculateDuration(
        form.getFieldValue('init_hour') || defaultHour(),
        watchDuration,
      ),
    })
  }, [watchDuration, event?.updated_at])

  // on init_hour change
  useEffect(() => {
    if (watchInitTime === undefined) {
      return
    }

    form.setFieldsValue({
      end_hour: calculateDuration(watchInitTime, watchDuration),
    })
  }, [watchInitTime, event?.updated_at])

  const onConfirm = () => {
    const params = {
      event_id: event.id,
      date: form.getFieldValue('date').format('YYYY-MM-DD'),
      int_time: form.getFieldValue('init_hour').format('HH:mm'),
      end_time: form.getFieldValue('end_hour').format('HH:mm'),
    }

    if (
      params.date === event.date &&
      params.int_time === init_hour.format('HH:mm') &&
      params.end_time === end_hour.format('HH:mm')
    ) {
      notification.warning({
        message: 'Atenção',
        description: 'Valores iguais, altere data ou horário para continuar',
      })
      return
    }

    api
      .put(`/api/events/update-date-time`, params)
      .then((res) => {
        notification.success({
          message: 'Sucesso',
          description: 'Data e hora alteradas com sucesso',
        })
        queryClient.invalidateQueries(['event', event.id])
        setModalChangeTime(false)
      })
      .catch((err) => {
        notification.error({
          message: 'Erro',
          description: err.response.data.message,
        })
      })
  }

  const defaultHour = () => {
    const now = init_hour || dayjs()
    const minutes = now.minute()
    const minutesRounded = minutes < 30 ? 0 : 30
    return now.minute(minutesRounded)
  }

  const calculateDuration = (init, duration) => {
    const endHour = init.add(duration, 'hour')
    return endHour
  }

  return (
    <>
      {/* <Button type="default" onClick={() => setModalChangeTime(true)}>
        Mudar hórario
      </Button> */}
      <a onClick={() => setModalChangeTime(true)}>Mudar data/hórario</a>

      <Modal
        title='Mudar data e hora da aula'
        open={modalChangeTime}
        onOk={() => onConfirm()}
        onCancel={() => setModalChangeTime(false)}>
        <div>
          <p>Tem certeza que deseja trocar a data e hora da aula?</p>

          <Row gutter={[8, 8]}>
            <Col span={12}>
              <strong>Data:</strong> {dayjs(event?.date).format('DD/MM/YYYY')}
            </Col>
            <Col span={12}>
              <strong>Duração:</strong> {duration}
            </Col>
            <Col span={12}>
              <strong>Início:</strong> {init_hour.format('HH:mm')}
            </Col>
            <Col span={12}>
              <strong>Fim:</strong> {end_hour.format('HH:mm')}
            </Col>

            <Col span={24}>
              <Form form={form} layout='vertical'>
                <Form.Item
                  key={'date'}
                  name={'date'}
                  label={'Data'}
                  initialValue={date}>
                  <DatePicker
                    format={'DD/MM/YYYY'}
                    allowClear={false}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  key={'init_hour'}
                  name={'init_hour'}
                  label={'Inicio'}
                  initialValue={init_hour}>
                  <TimePicker
                    allowClear={false}
                    format={'HH:mm'}
                    minuteStep={5}
                    onChange={(time, timeString) => {}}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  key={'duration'}
                  name={'duration'}
                  label={'Duração'}
                  initialValue={duration}>
                  <InputNumber
                    min={0.5}
                    max={4.0}
                    step={0.5}
                    onChange={(value) => {
                      form.setFieldsValue({
                        end_hour: calculateDuration(
                          form.getFieldValue('init_hour') || defaultHour(),
                          value,
                        ),
                      })
                    }}
                    disabled={type === 'Grupo'}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  key={'end_hour'}
                  name={'end_hour'}
                  label={'Fim'}
                  initialValue={calculateDuration(
                    form.getFieldValue('init_hour') || defaultHour(),
                    form.getFieldValue('duration') || 0.5,
                  )}>
                  <TimePicker
                    allowClear={false}
                    format={'HH:mm'}
                    disabled
                    value={form.getFieldValue('init_hour')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default ChangeTime
