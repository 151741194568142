import { InfoCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import {
  Table as AntTable,
  Card,
  Col,
  Popover,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd'
import type { TableProps } from 'antd/es/table'
import { SorterResult } from 'antd/es/table/interface'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { InlineAvatar } from '~/components/InlineAvatar'
import Table from '~/components/Table'
import { MaskMoney } from '~/helpers/Masks'
import api from '~/services/axios'

export default function TablePaymentsByMonth() {
  const [page, setPage] = useState([1, 10])
  const [sorter, setSorter] = useState<string | null>(null)
  const [year, setYear] = useState(new Date().getFullYear())
  const [onlyUsers, setOnlyUsers] = useState<string[] | null>(null)

  const { Title, Text } = Typography

  const isMobile = useMediaQuery({ query: '(max-width: 1050px)' })

  const {
    isLoading: isLoadingFilterYearWhitEvents,
    data: filterYearWhitEvents,
  } = useQuery(['filterYearWhitEvents'], () => {
    return api.get('/api/filters/years-with-events').then((res) => res.data)
  })

  const { isLoading: isLoadingPaymentsByMonth, data: paymentsByMonth } =
    useQuery(
      ['paymentsByMonth', page, year, sorter, onlyUsers],
      () => {
        return api
          .get('/api/statistics/students/by-month', {
            params: {
              page: page[0],
              pageSize: page[1],
              year,
              sort: sorter,
              students: onlyUsers,
            },
          })
          .then((response) => response.data)
          .catch((err) => {})
      },
      {
        cacheTime: 0,
      },
    )

  const { data: filterStudents } = useQuery(['filterStudents'], () => {
    return api.get('/api/filters/students').then((res) => res.data)
  })

  const onChange: TableProps<'columns'>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra,
  ) => {
    if (
      (sorter as SorterResult<'columns'>)?.field === 'user' ||
      extra?.action === 'sort'
    ) {
      let order: string | null = null
      if ((sorter as SorterResult<'columns'>)?.order === 'ascend') {
        order = 'ascend'
      } else if ((sorter as SorterResult<'columns'>)?.order === 'descend') {
        order = 'descend'
      } else {
        order = null
      }
      setSorter(order)
    }
    if (extra?.action === 'paginate') {
      setPage([pagination.current ?? 1, pagination.pageSize ?? 10])
    }

    if (extra?.action === 'filter') {
      setPage([1, pagination.pageSize ?? 10])

      if (filters?.user && filters?.user.length > 0) {
        const arrayIds = (filters?.user as string[]) || []
        setOnlyUsers(arrayIds)
      } else {
        setOnlyUsers(null)
      }
    }
  }

  const columns = [
    {
      title: (
        <Tooltip title='Ao selecionar alunos no filtro, a tabela exibirá apenas os alunos selecionados, que possuem pagamentos no ano selecionado.'>
          Aluno <InfoCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'user',
      key: 'user',
      fixed: 'left',
      sorter: true,
      sortOrder: sorter,
      filters: (filterStudents || []).map((user) => ({
        text: user.label,
        value: user.value,
      })),
      render: (_, item) => (
        <a href={`/portal/students/${item.id}?view=statement`}>
          <InlineAvatar name={item?.user?.name} src={item?.user?.picture} />
          {item?.user?.name}
        </a>
      ),
    },
    ...(paymentsByMonth?.data[0]?.open_payments?.map((month, index) => {
      return {
        title: month.date,
        width: 120,
        dataIndex: ['open_payments', index],
        key: `open_payments`,
        render: (item, records) => {
          return (
              item?.total === 0 && records?.paid_payments[index]?.total === 0
            ) ?
              <Text type='secondary'>{MaskMoney(0)}</Text>
            : <Popover
                content={
                  <div>
                    <p>Qtd pago: {records?.paid_payments[index]?.count}</p>
                    <p>Qtd em aberto: {item?.count}</p>

                    <p>
                      Valor pago:{' '}
                      {MaskMoney(records?.paid_payments[index]?.amount)}
                    </p>
                    <p>Valor em aberto: {MaskMoney(item?.amount)}</p>

                    <p>
                      Descontos concedidos:{' '}
                      {MaskMoney(records?.discount + records?.allowance)}
                    </p>
                    <p>
                      Descontos proposto pagamentos em aberto:{' '}
                      {MaskMoney(item?.discount + item?.allowance)}
                    </p>

                    <p>
                      Total pago:{' '}
                      {MaskMoney(records?.paid_payments[index]?.total)}
                    </p>
                    <p>Total em aberto: {MaskMoney(item?.total)}</p>
                  </div>
                }
                title='Pagamentos'>
                <>
                  {records?.paid_payments[index]?.total > 0 && (
                    <Text type='success'>
                      {MaskMoney(records?.paid_payments[index]?.total)}
                    </Text>
                  )}
                  <br style={isMobile ? { display: 'none' } : {}} />
                  {records?.paid_payments[index]?.total > 0 &&
                    item.total > 0 && <> - </>}
                  <br style={isMobile ? { display: 'none' } : {}} />
                  {item?.total > 0 && (
                    <Text type='danger'>{MaskMoney(item?.total)}</Text>
                  )}
                </>
              </Popover>
        },
      }
    }) || []),
    {
      title: 'Acumulado Ano',
      dataIndex: 'student_total',
      key: 'student_total',
      fixed: 'right',
      width: 120,
      render: (_, item) => {
        const acc_open = item?.open_payments.reduce((acc, curr) => {
          return acc + curr.total
        }, 0)
        const acc_paid = item?.paid_payments.reduce((acc, curr) => {
          return acc + curr.total
        }, 0)

        return (
          <>
            {acc_paid > 0 && <Text type='success'>{MaskMoney(acc_paid)}</Text>}
            <br style={isMobile ? { display: 'none' } : {}} />
            {acc_paid > 0 && acc_open > 0 && <> - </>}
            <br style={isMobile ? { display: 'none' } : {}} />
            {acc_open > 0 && <Text type='danger'>{MaskMoney(acc_open)}</Text>}
            {acc_paid === 0 && acc_open === 0 && (
              <Text type='secondary'>{MaskMoney(0)}</Text>
            )}
          </>
        )
      },
    },
  ]

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} md={24} xl={24}>
        <Card
          loading={isLoadingPaymentsByMonth}
          bordered={false}
          style={{ height: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={16} xl={18}>
              <Title level={5}>Pagamentos alunos</Title>
            </Col>
            <Col xs={24} sm={12} md={8} xl={6}>
              <Text
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginBottom: 8,
                }}
                type='secondary'>
                Visualizar pagamentos do ano de:
              </Text>
              <Select
                loading={isLoadingFilterYearWhitEvents}
                defaultValue={
                  filterYearWhitEvents?.[0]?.value || new Date().getFullYear()
                }
                style={{
                  width: '100%',
                  marginLeft: 'auto',
                }}
                onChange={(value) => {
                  setYear(value)
                }}
                value={year}
                options={filterYearWhitEvents?.map(({ label, value }) => ({
                  label,
                  value,
                }))}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Table
        size='small'
        bordered
        style={{ padding: '0 8px' }}
        scroll={{ x: 'max-content' }}
        dataSource={paymentsByMonth?.data || []}
        loading={isLoadingPaymentsByMonth}
        summary={(pageData) => {
          // total for every month
          // init object with 12 months
          const total: { total_open: number; total_paid: number }[] =
            Array.from({ length: 12 }, () => ({
              total_open: 0,
              total_paid: 0,
            }))

          pageData.forEach((item) => {
            item.open_payments.forEach((month, index) => {
              total[index].total_open = total[index].total_open + month.total
            })
          })

          pageData.forEach((item) => {
            item.paid_payments.forEach((month, index) => {
              total[index].total_paid = total[index].total_paid + month.total
            })
          })

          return (
            <AntTable.Summary.Row>
              <AntTable.Summary.Cell index={0}>Total</AntTable.Summary.Cell>
              {total.map((item, index) => (
                <AntTable.Summary.Cell key={index} index={index + 1}>
                  {item?.total_paid > 0 && (
                    <Text type='success'>{MaskMoney(item?.total_paid)}</Text>
                  )}
                  <br style={isMobile ? { display: 'none' } : {}} />
                  {item?.total_paid > 0 && item?.total_open > 0 && <> - </>}
                  <br style={isMobile ? { display: 'none' } : {}} />
                  {item?.total_open > 0 && (
                    <Text type='danger'>{MaskMoney(item?.total_open)}</Text>
                  )}
                  {item?.total_paid === 0 && item?.total_open === 0 && (
                    <Text type='secondary'>{MaskMoney(0)}</Text>
                  )}
                </AntTable.Summary.Cell>
              ))}
              <AntTable.Summary.Cell index={13}>
                {total.reduce((acc, curr) => {
                  return acc + curr.total_paid
                }, 0) > 0 && (
                  <Text type='success'>
                    {MaskMoney(
                      total.reduce((acc, curr) => {
                        return acc + curr.total_paid
                      }, 0),
                    )}
                  </Text>
                )}
                <br style={isMobile ? { display: 'none' } : {}} />
                {total.reduce((acc, curr) => {
                  return acc + curr.total_paid
                }, 0) > 0 &&
                  total.reduce((acc, curr) => {
                    return acc + curr.total_open
                  }, 0) > 0 && <> - </>}
                <br style={isMobile ? { display: 'none' } : {}} />
                {total.reduce((acc, curr) => {
                  return acc + curr.total_open
                }, 0) > 0 && (
                  <Text type='danger'>
                    {MaskMoney(
                      total.reduce((acc, curr) => {
                        return acc + curr.total_open
                      }, 0),
                    )}
                  </Text>
                )}
                {total.reduce((acc, curr) => {
                  return acc + curr.total_paid
                }, 0) === 0 &&
                  total.reduce((acc, curr) => {
                    return acc + curr.total_open
                  }, 0) === 0 && <Text type='secondary'>{MaskMoney(0)}</Text>}
              </AntTable.Summary.Cell>
            </AntTable.Summary.Row>
          )
        }}
        rowKey={'id'}
        columns={columns}
        onChange={onChange}
        sticky={{
          //offsetSummary: 55,
          offsetHeader: 55,
          offsetScroll: 0,
        }}
        pagination={{
          pageSize: paymentsByMonth?.per_page || 10,
          total: paymentsByMonth?.total || 0,
          current: paymentsByMonth?.current_page || 1,
          pageSizeOptions: ['10', '20', '50', '100', '150', '200'],
          showSizeChanger: true,
        }}
      />
    </Row>
  )
}
