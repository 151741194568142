import { useQuery } from '@tanstack/react-query'
import { Row, Space, Typography } from 'antd'
import React, { useEffect } from 'react'
import api from '~/services/axios'
import useTitleStore from '~/stores/useTitleStore'
import TablePaymentsByMonth from './components/TablePaymentsByMonth'

const DashPaymentStudents = () => {
  const { setTitle } = useTitleStore()

  const { Text } = Typography

  const { isLoading, data: user } = useQuery(['me'], () => {
    return api.get('/api/user/me').then((res) => res.data)
  })

  useEffect(() => {
    setTitle({
      title: (
        <>
          {`Olá, ${user?.name}`}{' '}
          <img
            src={'../../assets/img/icons/waving-hand.png'}
            width={24}
            style={{ marginBottom: '.5rem' }}
          />
          <br />
          <Text type='secondary' style={{ fontSize: '.7rem' }}></Text>
        </>
      ),
      avatar: {
        src: user?.picture,
        id: user?.id,
      },
      subTitle: `${
        'São Paulo, ' +
        Intl.DateTimeFormat('pt-BR', {
          localeMatcher: 'best fit',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date())
      }`,
      routes: [],
    })
  }, [isLoading])

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      <Row gutter={[16, 16]}>
        <TablePaymentsByMonth />
      </Row>
    </Space>
  )
}

export default DashPaymentStudents
