import { LockOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import {
  Card,
  Col,
  Descriptions,
  Row,
  Space,
  Statistic,
  Typography,
} from 'antd'
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { InlineAvatar } from '~/components/InlineAvatar'
import Logs from '~/components/Teachers/Logs'
import Payments from '~/components/Teachers/Payments'
import Profile from '~/components/Teachers/Profile'
import { MaskMoney } from '~/helpers/Masks'
import api from '~/services/axios'
import useContextStore from '~/stores/useContextStore'

const ShowTeacherPage = () => {
  const { currentProfile } = useContextStore()

  const { id } = useParams()
  const query = new URLSearchParams(useLocation().search)

  const [currentView, setCurrentView] = useState(query.get('view') || 'profile')

  const { data: teacher, isLoading } = useQuery(['teacher', id], () =>
    api.get(`/api/teachers/${id}`).then((res) => res.data),
  )

  const tabs = [
    {
      key: 'profile',
      tab: 'Perfil',
      children: <Profile id={id || ''} />,
    },
    {
      key: 'statement',
      tab: 'Extrato',
      children: <Payments key={'statement'} id={id || ''} />,
    },
    {
      key: 'logs',
      tab: <span>{currentProfile !== 'admin' && <LockOutlined />} Logs</span>,
      children: <Logs key={'logs'} id={id || ''} />,
      disabled: currentProfile !== 'admin',
    },
  ]

  const handleTabChange = (key) => {
    if (key === 'logs' && currentProfile !== 'admin') {
      key = 'profile'
    }

    setCurrentView(key)
    query.set('view', key)
    window.history.replaceState({}, '', `${window.location.pathname}?${query}`)
  }

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      <Card bordered={false}>
        <Descriptions
          title={
            <>
              <InlineAvatar name={teacher?.name} src={teacher?.picture} />{' '}
              {teacher?.name}
            </>
          }>
          <Descriptions.Item label='Username'>
            {teacher?.username}
          </Descriptions.Item>
          <Descriptions.Item label='Telefone'>
            {teacher?.phone}
          </Descriptions.Item>
          <Descriptions.Item label='Endereço'>
            {teacher?.adress}
          </Descriptions.Item>

          {/* <Descriptions.Item label="Ano"> */}
          {/*  {teacher?.grades */}
          {/*    .filter((grade) => grade.year === new Date().getFullYear()) */}
          {/*    .map((grade) => ( */}
          {/*      <Tag key={grade.type}>{grade.type}</Tag> */}
          {/*    ))} */}
          {/* </Descriptions.Item> */}
        </Descriptions>
      </Card>

      {/* cards statistics */}
      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} md={5} xl={5}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title={`${teacher?.statistics?.open_payments_count} Pagamentos em Aberto`}
              value={MaskMoney(teacher?.statistics?.open_payments_total)}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>

        <Col xs={12} sm={12} md={5} xl={5}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title={`${teacher?.statistics?.future_events_count} Aulas Futuras`}
              value={MaskMoney(teacher?.statistics?.future_events_total)}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>

        <Col xs={12} sm={12} md={5} xl={5}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title={
                `${teacher?.statistics?.open_payments_count} Pag. em Aberto + ` +
                `${teacher?.statistics?.future_events_count} Aulas Futuras`
              }
              value={MaskMoney(
                (teacher?.statistics?.future_events_total || 0) +
                  (teacher?.statistics?.open_payments_total || 0),
              )}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>

        <Col xs={12} sm={12} md={5} xl={5}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title={`${teacher?.statistics?.payments_count} Aulas Pagas`}
              value={MaskMoney(teacher?.statistics?.payments_total)}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>

        <Col xs={24} sm={24} md={4} xl={4}>
          <Card
            size='small'
            loading={isLoading}
            bordered={false}
            style={{ height: '100%' }}>
            <Statistic
              title='Total de Aulas'
              value={teacher?.statistics?.events_count}
              valueRender={(value) => (
                <Typography.Text style={{ fontSize: '24px' }}>
                  {value}
                </Typography.Text>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Card
        tabList={tabs}
        activeTabKey={currentView}
        onTabChange={(key) => {
          handleTabChange(key)
        }}
        styles={{
          body: {
            padding: 8,
          },
        }}
        bordered={false}>
        {/* <Row gutter={[8, 8]}>
          {tabs.find((tab) => tab.key === currentView)?.children}
        </Row> */}
      </Card>
    </Space>
  )
}

export default ShowTeacherPage
