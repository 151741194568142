import { InfoCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Card,
  Dropdown,
  Modal,
  notification,
  Space,
  Tag,
  Tooltip,
} from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useEffect, useState } from 'react'
import { CgLock, CgLockUnlock, CgTrash } from 'react-icons/cg'
import { MdAdd, MdDetails, MdEdit, MdMoreVert } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '~/services/queryClient'
import colors from '~/styles/colors'
import Table from '../../../components/Table'
import api from '../../../services/axios'
import useTitleStore from '../../../stores/useTitleStore'
import CreateNewSchoolModal from './CreateNewSchoolModal'
import UpdateSchoolModal from './UpdateSchoolModal'

interface DataType {
  id: string
  name: string
  address: string
  active: boolean
}

const Schools = () => {
  const { setTitle } = useTitleStore()
  const navigate = useNavigate()
  const [modalNewSchool, setModalNewSchool] = useState(false)
  const [modalEditSchool, setModalEditSchool] = useState<null | DataType>(null)
  const [modal, contextHolder] = Modal.useModal()

  const { isLoading, data } = useQuery(['listAllSchools'], () => {
    return api
      .get('/api/schools')
      .then((res) => res.data)
      .catch((err) => {})
  })

  useEffect(() => {
    setTitle({
      title: 'Escolas',
      subTitle: 'Gerenciar escolas',
      routes: [
        { path: '/portal/admin', breadcrumbName: 'Administrativo' },
        {
          path: '/portal/admin/schools',
          breadcrumbName: 'Escolas',
        },
      ],
    })
  }, [])

  const handleStatusChange = (active: boolean, id: string) => {
    modal.confirm({
      title: 'Alterar status da escola',
      content: (
        <>
          Deseja realmente <b>{active ? 'ativar' : 'desativar'}</b> essa escola?
          <br />
          <br />
          {active ?
            'Escola será exibida na listagem de escolas disponíveis para seleção em formulários.'
          : 'Escola será removida da listagem de escolas disponíveis para seleção em formulários.'
          }
        </>
      ),
      cancelText: 'Cancelar',
      closable: true,
      okCancel: true,
      okText: active ? 'Ativar' : 'Desativar',
      onOk() {
        api
          .patch(`/api/schools/${id}/active`, {
            active,
          })
          .then(() => {
            queryClient.invalidateQueries({ queryKey: ['listAllSchools'] })
            notification.success({
              message: `Escola ${
                active ? 'ativada' : 'desativada'
              } com sucesso!`,
            })
          })
          .catch(() => {
            notification.error({
              message: 'Erro ao alterar status da escola',
            })
          })
      },
    })
  }

  const handleDelete = (id: string) => {
    modal.error({
      title: 'Excluir escola',
      content: (
        <>
          Deseja realmente excluir essa escola?
          <br />
          <br />
          Escolas somente podem ser excluídas se não possuírem alunos vinculados
          (cadastro errados), caso contrário, desative a escola.
        </>
      ),
      cancelText: 'Cancelar',
      closable: true,
      okCancel: true,
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        api
          .delete(`/api/schools/${id}`)
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: ['listAllSchools'],
            })

            notification.success({
              message: 'Escola excluída com sucesso!',
            })
          })
          .catch(() => {
            notification.error({
              message: 'Erro ao excluir escola',
            })
          })
      },
    })
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => (
        <Space
          style={
            {
              //borderLeft: `4px solid ${item?.color}`,
              //paddingLeft: '8px',
            }
          }>
          <span>{item?.name}</span>
        </Space>
      ),
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: (
        <Tooltip title='Desativar escola remove a escola da listagem de escolas disponíveis para seleção em formulários.'>
          Ativo? <InfoCircleOutlined />
        </Tooltip>
      ),

      dataIndex: 'active',
      sorter: (a, b) => (a.active ? 1 : -1),
      key: 'active',
      filters: [
        {
          text: 'Ativo',
          value: true,
        },
        {
          text: 'Inativo',
          value: false,
        },
      ],
      onFilter: (value, record) => record?.active === value,
      render: (_, item) => (
        <Tag
          color={item?.active ? colors.status.success : colors?.status?.error}>
          {item?.active ? 'Ativo' : 'Inativo'}
        </Tag>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      width: '30px',
      render: (_, item) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 'see-details',
                icon: <MdDetails />,
                label: 'Ver detalhes da escola',
                onClick: () => {
                  navigate(`/portal/admin/schools/${item?.id}`)
                },
              },
              {
                key: 'edit',
                icon: <MdEdit />,
                label: 'Editar escola',
                onClick: () => {
                  setModalEditSchool(item)
                },
              },
              {
                key: 'status',
                icon: item?.active ? <CgLock /> : <CgLockUnlock />,
                label: item?.active ? 'Desativar escola' : 'Ativar escola',
                onClick: () => handleStatusChange(!item?.active, item?.id),
              },
              {
                key: 'delete',
                icon: <CgTrash />,
                label: 'Excluir escola',
                onClick: () => handleDelete(item?.id),
              },
            ],
          }}
          trigger={['click']}
          placement='bottomRight'>
          <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
            <MdMoreVert />
          </a>
        </Dropdown>
      ),
    },
  ]

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      <Card
        title='Listagem de Escolas'
        extra={
          <Button
            icon={<MdAdd />}
            type='primary'
            onClick={() => setModalNewSchool(true)}>
            Nova Escola
          </Button>
        }>
        <Table
          rowKey='id'
          loading={isLoading}
          columns={columns}
          dataSource={data}
          size={'small'}
        />
      </Card>

      <CreateNewSchoolModal
        modalNewSchool={modalNewSchool}
        setModalNewSchool={setModalNewSchool}
      />

      <UpdateSchoolModal
        modalEditSchool={modalEditSchool}
        setModalEditSchool={setModalEditSchool}
      />

      {contextHolder}
    </Space>
  )
}

export default Schools
