import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from '~/services/axios'
import useTitleStore from '~/stores/useTitleStore'

const ShowSchool = () => {
  const { setTitle } = useTitleStore()
  const { id } = useParams()

  const { data: school, isLoading } = useQuery(['school', id], () => {
    return api
      .get('/api/schools/' + id)
      .then((res) => res.data)
      .catch((_err) => {})
  })

  useEffect(() => {
    setTitle({
      title: 'Escola: ' + school?.name,
      subTitle: 'Detalhes da escola',
      routes: [
        { path: '/portal/admin', breadcrumbName: 'Administrativo' },
        {
          path: '/portal/admin/schools',
          breadcrumbName: 'Escolas',
        },
        {
          path: `/portal/admin/schools/${id}`,
          breadcrumbName: 'Escola: ' + school?.name,
        },
      ],
    })
  }, [school, isLoading])

  return (
    <div>
      <h1>{id}</h1>
    </div>
  )
}

export default ShowSchool
