import { Avatar } from 'antd'
import React from 'react'

type InlineAvatarProps = {
  name: string
  src?: string
}

export const InlineAvatar = ({ name, src, ...props }: InlineAvatarProps) => {
  const getInitials = (name: string) => {
    const splitName = name?.split(' ')
    if (splitName?.length === 1) {
      return (splitName?.[0]?.[0] || '') + (splitName?.[0]?.[1] || '')
    }
    return (splitName?.[0]?.[0] || '') + (splitName?.[1]?.[0] || '')
  }

  return (
    <Avatar
      src={src}
      style={{ backgroundColor: '#87d068', marginRight: '0.3rem' }}
      {...props}>
      {!src && getInitials(name)}
    </Avatar>
  )
}
