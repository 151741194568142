import { Form, Input, Modal, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import api from '~/services/axios'
import { queryClient } from '~/services/queryClient'

type SchoolProps = {
  id: string
  name: string
  address: string
}

interface UpdateSchoolModalProps {
  modalEditSchool: SchoolProps | null
  setModalEditSchool: (value: SchoolProps | null) => void
}

const UpdateSchoolModal = ({
  modalEditSchool,
  setModalEditSchool,
}: UpdateSchoolModalProps) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (modalEditSchool) {
      form.setFieldsValue(modalEditSchool)
    }
  }, [modalEditSchool])

  const onFinish = async (values: SchoolProps) => {
    api
      .put(`/api/schools/${values.id}`, values)
      .then(() => {
        queryClient.invalidateQueries(['listAllSchools'])

        notification.success({
          message: 'Escola atualizada com sucesso!',
        })

        setIsSubmitting(false)
        setModalEditSchool(null)
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao criar escola',
          description: error.response.data.message,
        })
        setIsSubmitting(false)
      })
  }

  return (
    <Modal
      open={Boolean(modalEditSchool)}
      onCancel={() => setModalEditSchool(null)}
      title={`Atualizar escola `}
      onOk={() => {
        setIsSubmitting(true)
        form.submit()
      }}
      okButtonProps={{
        loading: isSubmitting,
      }}>
      <Form form={form} onFinish={onFinish} layout={'vertical'}>
        <Form.Item label='Id' name='id' hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label='Nome'
          name='name'
          rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item label='Endereço' name='address' rules={[]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdateSchoolModal
