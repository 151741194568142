import { Form, Input, Modal, notification } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '~/services/axios'
import { queryClient } from '~/services/queryClient'

interface CreateNewSchoolModalProps {
  modalNewSchool: boolean
  setModalNewSchool: (value: boolean) => void
}

const CreateNewSchoolModal = ({
  modalNewSchool,
  setModalNewSchool,
}: CreateNewSchoolModalProps) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onFinish = async (values: any) => {
    api
      .post('/api/schools', values)
      .then((response) => {
        queryClient.invalidateQueries(['listAllSchools'])

        notification.success({
          message: 'Escola criada com sucesso!',
        })

        navigate(`/portal/admin/schools/${response.data.id}`)
        setIsSubmitting(false)
      })
      .catch((error) => {
        notification.error({
          message: 'Erro ao criar escola',
          description: error.response.data.message,
        })
        setIsSubmitting(false)
      })
  }

  return (
    <Modal
      open={modalNewSchool}
      onCancel={() => setModalNewSchool(false)}
      title='Nova Escola'
      onOk={() => {
        setIsSubmitting(true)
        form.submit()
      }}
      okButtonProps={{
        loading: isSubmitting,
      }}>
      <Form form={form} onFinish={onFinish} layout={'vertical'}>
        <Form.Item
          label='Nome'
          name='name'
          rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item label='Endereço' name='address' rules={[]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateNewSchoolModal
