import { Card, Col, Row } from 'antd'
import React, { useEffect } from 'react'
import {
  HiOutlineAcademicCap,
  HiOutlineBookOpen,
  HiOutlineFingerPrint,
  HiOutlineFlag,
  HiOutlineUserGroup,
} from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import Link from '~/components/Link'
import useTitleStore from '../../stores/useTitleStore'

const Admin = () => {
  const { setTitle } = useTitleStore()
  const navigate = useNavigate()
  const iconSize = 32

  const menuItems = [
    {
      icon: <HiOutlineFlag size={iconSize} />,
      name: `Salas`,
      path: '/portal/admin/rooms',
      tooltip: 'Salas',
    },
    {
      icon: <HiOutlineAcademicCap size={iconSize} />,
      name: `Matérias`,
      path: '/portal/admin/subjects',
      tooltip: 'Matérias',
    },
    {
      icon: <HiOutlineBookOpen size={iconSize} />,
      name: `Escolas`,
      disabled: false,
      path: '/portal/admin/schools',
      tooltip: 'Escolas',
    },
    {
      icon: <HiOutlineUserGroup size={iconSize} />,
      name: `Usuários`,
      path: '/portal/admin/users',
      tooltip: 'Usuários',
    },
    {
      icon: <HiOutlineFingerPrint size={iconSize} />,
      name: `Perfis`,
      disabled: false,
      path: '/portal/admin/profiles',
      tooltip: 'Perfis',
    },
  ]

  useEffect(() => {
    setTitle({
      title: 'Administrativo',
      subTitle: 'Painel administrativo',
      routes: [],
    })
  }, [])

  return (
    <Row gutter={[16, 16]}>
      {menuItems.map((item, index) => (
        <Col key={menuItems?.path} xs={24} sm={12} md={8} lg={8} xl={8}>
          <Card
            styles={{
              body: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 150,
                cursor: 'pointer',
              },
            }}
            hoverable
            onClick={() => {
              navigate(item.path)
            }}>
            {item.icon}
            <Link
              style={{
                marginTop: 8,
                fontSize: 16,
                fontWeight: 500,
              }}
              to={item.path}>
              {item.name}
            </Link>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default Admin
